@use '/src/styles/variables' as var;

.warning {
  background-color: var.$warning-light !important;
}

.info {
  background-color: var.$info-light !important;
}

.success {
  background-color: var.$success-light !important;
}

.notification-card {
  width: 400px;
  box-shadow: 0px 10px 18px 0px rgba(0, 0, 0, 0.1);

  .ant-card-head {
    padding: 12px !important;
  }
  .ant-card-body {
    padding: 0px !important;
    border-radius: 8px;
  }

  .notification-details {
    height: 350px;
    overflow: auto;
  }

  .ant-list-item {
    padding: 12px;
    cursor: pointer;
    overflow: hidden;

    &:hover {
      background-color: var.$natural-10;
    }

    &:hover {
      .title {
        font-weight: var.$w-600;
      }
    }
  }

  .title {
    font-size: var.$text-md;
    flex-basis: 80%;
    font-weight: var.$w-500;
  }

  .time {
    color: var.$natural-4;
    font-size: var.$text-sm;
  }
}
