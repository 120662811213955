@use '/src/styles/variables' as var;

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex: 1;
}

.ant-layout {
  background: #fff !important;
}

.custom-container {
  width: min(100% - 0, 65em);
  margin-inline: auto;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.nt-1 {
  color: var.$natural-2;
}

.nt-3 {
  color: var.$natural-3;
}

.nt-4 {
  color: var.$natural-4;
}

.nt-5 {
  color: var.$natural-5;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 1em;
}
.mt-2 {
  margin-top: 2em;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 1em;
}

.mb-2 {
  margin-bottom: 2em;
}

.mb-3 {
  margin-bottom: 3em;
}

.center {
  text-align: center;
}

.text-primary {
  color: var.$primary-color !important;
}

.text-semi-bold {
  font-weight: var.$w-500;
}

.text-bold {
  font-weight: var.$w-600;
}

.custom-card {
  .ant-card-body {
    padding: 0;
    border-radius: 12px;

    .title-section {
      font-size: 16px;
      font-weight: 600;
      padding: 8px 16px;
      background-color: #cbe0fd;
      color: var.$primary-color;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: 8px 8px 0 0;

      .icons {
        position: absolute;
        right: 16px;
        bottom: 8px;
        top: 8px;
      }
    }

    .description {
      padding: 24px;
      border-radius: 8px;
      background-color: #fafcfe;
    }
  }
}

.sidebar-layout {
  background-color: var.$natural-0;
  overflow: auto;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.dashboard-layout-drawer {
  .ant-drawer-content-wrapper {
    width: 280px !important;
  }

  .ant-drawer-body {
    padding: 0px !important;
  }
}

.ant-layout-sider {
  flex: 0 0 280px !important;
  max-width: 280px !important;
  min-width: 200px;
  background-color: var.$natural-0 !important;
  border-right: 1px solid #f0f0f0;
}

.ant-layout-sider-collapsed {
  flex: 0 0 0px !important;
  max-width: 0px !important;
  min-width: 0px;
  width: 0px;
}

.ant-menu-title-content {
  font-size: 14px;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-btn-text {
  color: #005bd4;

  &:hover {
    color: #005bd4 !important;
    background-color: rgb(0, 91, 212, 0.06) !important;
  }
}

.ant-menu-item-group-title,
.small-text {
  font-size: var.$text-sm !important;
}

.ant-input-password,
.ant-picker {
  height: 40px;
}

.search-wrapper {
  .ant-input {
    height: 40px;
  }
}

.ant-select {
  width: 100%;
}

.app-standard-header {
  padding-left: 20px;
  padding-right: 20px;
  color: #000;
  background-color: #fff;
  height: 64px;
  line-height: 1;
  position: sticky;
  right: 0;
  left: 0;
  top: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-card {
  margin-top: 2em;
  border-radius: 0;
  .ant-card-body {
    padding: 12px 24px;
  }
}

.ant-table-thead {
  .ant-table-cell {
    background-color: #3d82f0 !important;
    color: #ffffff !important;
  }
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
  // color: #ffffff;
}

.ant-table-column-sorter {
  color: white !important;
}

.even-row {
  background-color: #fafafa;
}

.odd-row {
  background-color: #f3faff;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: #f3faff !important;
}

.app-header-card {
  border-radius: 0;

  .ant-card-body {
    padding: 12px !important;
  }
}

.react-tel-input {
  .form-control {
    height: 40px !important;
    width: 100% !important;
  }
}

.error-boundary {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.ant-color-picker-trigger {
  border-radius: 50px;

  .ant-color-picker-color-block {
    border-radius: 50px;
  }
}

.ant-tabs-nav::before {
  border-bottom: none !important;
}

.ant-table-selection-extra {
  .ant-dropdown-trigger {
    .anticon {
      color: #fff !important;
    }
  }
}

@media (min-width: 992px) {
  .menu {
    display: none;
  }
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #8e8e8e #f4f4f4;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 4px;
  width: 4px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #f4f4f4;
}

*::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

*::-webkit-scrollbar-track:active {
  background-color: #f4f4f4;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #8e8e8e;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a0;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #a0a0a0;
}
